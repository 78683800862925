import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import office from './office.jpg';
import stairs from './stairs.jpg';
import barber from './barber.jpg';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Link} from "@mui/material";

export default function ImageProjectsList() {



    return (
        <Box width={'1000'} alignItems={'center'}>
            <Box sx={{width: '600', height: '300'}}>
                <ImageList cols={3}>

                    {itemData.map((item) => (

                        <ImageListItem key={item.img}>

                            <img
                                src={`${item.img}?w=246&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=246&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                            <Link href={'https://t.me/sk_smr24'}>
                            <ImageListItemBar
                                title={item.title}
                            />
                            </Link>
                        </ImageListItem>

                    ))}
                </ImageList>

            </Box>
        </Box>
    );
}

const itemData = [
    {
        img: office,
        title: 'Ремонт офисов',

        featured: true,
    },
    {
        img: stairs,
        title: 'Реставрация старых зданий',
    },
    {
        img: barber,
        title: 'Ремонт коммерческих помещений',
    }
];