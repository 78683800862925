import logo from './sksmr24.jpg';
import support from './suport.png';
import molotok from './molotok.png';
import idea from './Idea.png';
import shielf from './shield.png';
import barber from './barber.png';
import visitka from './visitka.jpg';
import './App.css';
import {Box, Typography, Grid, Button, Link} from "@mui/material";
import ImageProjectsList from "./ImageProjectsList";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Flex from "./Flex";
import TelegramIcon from '@mui/icons-material/Telegram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function App() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function ClickableBtton() {
        return (
            <Flex justifyContent='center'>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#CF8412',
                        borderRadius: "100px",
                        margin: '20px'
                    }}
                    onClick={handleClickOpen}
                >
                    <Typography
                        sx={{
                            color: '#282828'
                        }}
                        fontFamily={'Montserrat'}
                    >
                        КОНТАКТНЫЕ ДАННЫЕ
                    </Typography>
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        <Typography
                            sx={{
                                color: '#282828'
                            }}
                            fontFamily={'Montserrat'}
                            //Заказать звонок
                        >
                            КОНТАКТЫ
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            /*<Typography
                                sx={{
                                    color: '#282828'
                                }}
                                fontFamily={'Montserrat'}
                            >
                                Здравствуйте, оставьте свои контактные данные и наш специалист с вами свяжется с вами в
                                рабочее время!
                            </Typography>*/
                        >
                            <img src={visitka} width={'100%'}/>
                        </DialogContentText>
                        <Flex direction={'row'} justifyContent={'center'}>
                            <Button>
                                <Link color={"#000000"} href={'tel:+7(967)021-66-21'}><PhoneIcon/></Link>
                            </Button>
                            <Button>
                                <Link color={"#000000"} href={'https://t.me/Yurchik_Roman'}><TelegramIcon/></Link>
                            </Button>
                            <Button>
                                <Link color={"#000000"} href="mailto:sk_smr24@mail.ru"><AlternateEmailIcon/></Link>
                            </Button>
                            <Button>
                                <Link color={"#000000"} href={'https://t.me/sk_smr24'}><WhatsAppIcon/></Link>
                            </Button>
                            <Button>
                                <Link color={"#000000"} href={'https://t.me/sk_smr24'}><QrCodeIcon/></Link>
                            </Button>
                        </Flex>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            <Typography
                                sx={{
                                    color: '#282828'
                                }}
                                fontFamily={'Montserrat'}
                            >
                                Закрыть
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Flex>
        );
    }

    return (
        <Flex direction="column">
            <Flex direction="column" textAlign="center">
                <Box height={70}>
                    <Flex direction="row" textAlign="center" sizeMax={21}>

                        <Flex.Item size={7}>
                            <Box sx={{backgroundColor: "white", opacity: 0.5}} height={68} justifyContent='start'>
                                <img src={logo} height={'100%'} align={"left"}/>
                            </Box>
                        </Flex.Item>
                        <Flex.Item size={14}>
                            <Box marginX={0.78} marginY={2} justifyContent='center'>
                                <Typography align={"left"} fontFamily={'Montserrat'} fontSize={10}>
                                    <Link color={"#000000"} href="tel:+7(967)021-66-21">
                                        +7(967)021-66-21
                                    </Link>

                                </Typography>
                                <Typography align={"left"} fontFamily={'Montserrat'} color="#000000" fontSize={10}>
                                    <Link color={"#000000"} href="mailto:sk_smr24@mail.ru">
                                        sk_smr24@mail.ru
                                    </Link>
                                </Typography>
                                <Typography align={"left"} fontFamily={'Montserrat'} fontSize={10}>
                                    Адрес:
                                    <Link color={"#000000"}
                                          href={'https://yandex.by/maps/213/moscow/house/bagrationovskiy_proyezd_7k2/Z04YcwdhS0IBQFtvfXt1cHtlbQ==/?from=tabbar&indoorLevel=1&ll=37.501277%2C55.741769&mode=search&sctx=ZAAAAAgAEAAaKAoSCfoOfuIAljtAERRdF35w%2BEpAEhIJcVga%2BFENtz8RTdcTXRd%2BsD8iBgABAgMEBSgKOABA9p4GSAFiM3JlYXJyPXNjaGVtZV9Mb2NhbC9HZW8vQ29sbGVjdGlvbnMvRW5hYmxlZE1peD1mYWxzZWoCdWGdAc3MTD2gAQCoAQC9ARxMI%2F7CAQEA6gEA8gEA%2BAEAggJJ0LHQsNCz0YDQsNGC0LjQvtC90L7QstGB0LrQuNC5INC%2F0YDQvtC10LfQtCA30LoyLCDQutC%2B0LwuIDE0INC40L3QtNC10LrRgYoCAJICAzIxM5oCDGRlc2t0b3AtbWFwcw%3D%3D&sll=37.501277%2C55.741769&source=serp_navig&sspn=0.012288%2C0.004626&text=багратионовский%20проезд%207к2%2C%20ком.%2014%20индекс&z=16.81'}>
                                        г.Москва, Багратионовский проезд д. 7к2, ком. 14
                                    </Link>
                                </Typography>
                            </Box>
                        </Flex.Item>
                    </Flex>
                </Box>
            </Flex>

            <Flex sizeMax={21} direction="column" textAlign="center" justifyContent={'center'}>
                <Flex direction="row" spacing={2} justifyContent={"space-around"} marginY={0.5}>
                    <Flex.Item item size={5} sx={{backgroundColor: "white", opacity: 0.5}}>
                        <img src={barber} width={'100%'}/>
                    </Flex.Item>

                </Flex>
            </Flex>
            <Flex direction={'column'} alignItems={'center'}>
                <Flex.Item>
                    <ClickableBtton/>
                </Flex.Item>
                <Flex direction={'row'} justifyContent={'center'}>
                    <Button>
                        <Link color={"#000000"} href={'tel:+7(967)021-66-21'}><PhoneIcon/></Link>
                    </Button>
                    <Button>
                        <Link color={"#000000"} href={'https://t.me/Yurchik_Roman'}><TelegramIcon/></Link>
                    </Button>
                    <Button>
                        <Link color={"#000000"} href="mailto:sk_smr24@mail.ru"><AlternateEmailIcon/></Link>
                    </Button>
                    <Button>
                        <Link color={"#000000"} href={'https://t.me/sk_smr24'}><WhatsAppIcon/></Link>
                    </Button>
                    <Button>
                        <Link color={"#000000"} href={'https://t.me/sk_smr24'}><QrCodeIcon/></Link>
                    </Button>
                </Flex>
            </Flex>

            <Box sx={{backgroundColor: '#CF8412'}}>
                <Flex direction="column" textAlign="center" sx={{backgroundColor: '#CF8412'}}>
                    <canvas height={10}></canvas>
                    <Typography
                        sx={{
                            color: '#282828',
                            padding: '10px'
                        }}
                        variant={'h4'}
                        fontFamily={'Montserrat'}
                    >
                        НАШИ ПРЕИМУЩЕСТВА
                    </Typography>
                    <canvas height={10}></canvas>

                    <Flex direction="column" spacing={2} justifyContent={"space-around"}>
                        <Flex direction={'row'}>
                            <Flex.Item size={6} height={300} wrap={'wrap'}>

                                <img src={support} height={'35%'}/>

                                <Typography
                                    sx={{
                                        color: '#282828'
                                    }}
                                    variant={'body1'}
                                    fontFamily={'Montserrat'}
                                >
                                    ПРОФЕССИОНАЛЬНАЯ КОНСУЛЬАЦИЯ
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#282828',
                                        padding: '10px'
                                    }}
                                    variant={'caption'}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    ВЫЕЗД СПЕЦИАЛИСТА И СОСТАВЛЕНИЕ ПОДРОБНОЙ СМЕТЫ <b>БЕСПЛАТНО</b>
                                </Typography>
                            </Flex.Item>
                            <Flex.Item size={6} alignItems={'center'} height={300}>
                                <img src={molotok} height={'35%'}/>
                                <Typography
                                    sx={{
                                        color: '#282828'
                                    }}
                                    variant={'body1'}
                                    fontFamily={'Montserrat'}
                                >
                                    ПРОЕКТЫ ЛЮБОЙ СЛОЖНОСТИ
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#282828',
                                        padding: '10px'
                                    }}
                                    variant={'caption'}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    КАЧЕСТВЕННОЕ ВЫПОЛНЕНИЕ РАБОТ ЛЮБОЙ СЛОЖНОСТИ
                                </Typography>
                            </Flex.Item>
                        </Flex>
                        <Flex direction={'row'} sizeMax={12} wrap={'wrap'}>
                            <Flex.Item item size={6} height={300}>

                                <img src={shielf} height={'35%'}/>

                                <Typography
                                    sx={{
                                        color: '#282828'
                                    }}
                                    variant={'body1'}
                                    fontFamily={'Montserrat'}
                                >
                                    ГАРАНТИЯ КАЧЕСТВА
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#282828',
                                        padding: '10px'
                                    }}
                                    variant={'caption'}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    РАБОТАЕМ СТРОГО ПО ДОГОВОРУ. ОПЛАТА ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ. <b>ГАРАНТИЯ 24
                                    МЕСЯЦА</b>
                                </Typography>

                            </Flex.Item>
                            <Flex.Item size={6} height={300}>

                                <img src={idea} height={'35%'}/>

                                <Typography
                                    sx={{
                                        color: '#282828'
                                    }}
                                    fontFamily={'Montserrat'}
                                    variant={'body1'}
                                >
                                    РЕАЛИЗУЕМ ВАШИ ИДЕИ
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#282828',
                                        padding: '10px'
                                    }}
                                    variant={'caption'}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    БЕРЕМСЯ ЗА ПРОЕКТЫ КОТОРЫХ <b>БОЯТСЯ</b> КОНКУРЕНТЫ
                                </Typography>
                            </Flex.Item>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
            <Box sx={{backgroundColor: '#282828'}}>
                <Flex direction="column" textAlign="center" sx={{backgroundColor: '#282828'}}>
                    <canvas height={10}></canvas>
                    <Typography sx={{color: '#CF8412', margin: '10px'}} fontSize={24} fontFamily={'Montserrat'}>НАШИ
                        РАБОТЫ</Typography>
                    <Flex direction="column" justifyContent={'center'}>
                        <Flex.Item item xs={8}>
                            <ImageProjectsList/>
                        </Flex.Item>
                    </Flex>
                    <canvas height={10}></canvas>

                    <Flex direction="column" justifyContent={'center'}>
                        <Flex.Item item xs={6}>
                            <ClickableBtton/>
                        </Flex.Item>
                        <Flex direction={'row'} justifyContent={'center'}>
                            <Button size={'small'}>
                                <Link color={"#CF8412"} href={'tel:+7(967)021-66-21'}><PhoneIcon/></Link>
                            </Button>
                            <Button size={'small'}>
                                <Link color={"#CF8412"} href={'https://t.me/Yurchik_Roman'}><TelegramIcon/></Link>
                            </Button>
                            <Button size={'small'}>
                                <Link color={"#CF8412"} href="mailto:sk_smr24@mail.ru"><AlternateEmailIcon/></Link>
                            </Button>
                            <Button size={'small'}>
                                <Link color={"#CF8412"} href={'https://t.me/sk_smr24'}><WhatsAppIcon/></Link>
                            </Button>
                            <Button size={'small'}>
                                <Link color={"#CF8412"} href={'https://t.me/sk_smr24'}><QrCodeIcon/></Link>
                            </Button>
                        </Flex>
                        <Flex.Item item xs={8} justifyContent={'center'}>
                            <Flex justifyContent={'center'} direction={'row'}>
                                <Flex.Item></Flex.Item>
                                <Flex.Item>
                                <Typography
                                    align='left'
                                    sx={{
                                        color: '#CF8412'
                                    }}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    -КОМАНДА <u>ПРОФЕССИОНАЛОВ</u>
                                </Typography>
                                <Typography
                                    align='left'
                                    sx={{
                                        color: '#CF8412'
                                    }}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    -ГОТОВЫ РЕАЛИЗОВАТЬ ЛЮБЫЕ ВАШИ, ДАЖЕ САМЫЕ СМЕЛЫЕ,
                                    ИДЕИ!
                                </Typography>
                                <Typography
                                    align='left'
                                    sx={{
                                        color: '#CF8412'
                                    }}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    -СДАЕМ РАБОТЫ <u>ТОЧНО</u> В ОГОВОРЕННЫЕ СРОКИ.
                                </Typography>
                                <Typography
                                    align='left'
                                    sx={{
                                        color: '#CF8412'
                                    }}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >

                                    -<u>ИНДИВИДУАЛЬНЫЙ ПОДХОД</u>
                                </Typography>
                                <Typography
                                    align='left'
                                    sx={{
                                        color: '#CF8412'
                                    }}
                                    fontFamily={'Montserrat'}
                                    fontSize={11}
                                >
                                    -МЫ ЛЮБИМ НАШУ РАБОТУ И
                                    ДЕЛАЕМ ВСЕ
                                    ЧТОБЫ <u>ВЫ</u> БЫЛИ ДОВОЛЬНЫ РЕЗУЛЬТАТОМ
                                </Typography>
                                </Flex.Item>
                                <Flex.Item></Flex.Item>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                    <canvas height={30}></canvas>
                </Flex>
            </Box>
            <Box height={70}>
                <Flex direction="row" textAlign="center" sizeMax={21}>
                    <Flex.Item size={7}>
                        <Box sx={{backgroundColor: "white", opacity: 0.5}} height={68} justifyContent='start'>
                            <img src={logo} height={'100%'} align={"left"}/>
                        </Box>
                    </Flex.Item>
                    <Flex.Item size={14}>
                        <Box marginX={0.78} marginY={2} justifyContent='center'>
                            <Typography align={"left"} fontFamily={'Montserrat'} fontSize={10}>
                                <Link color={"#000000"} href="tel:+7(967)021-66-21">
                                    +7(967)021-66-21
                                </Link>

                            </Typography>
                            <Typography align={"left"} fontFamily={'Montserrat'} color="#000000" fontSize={10}>
                                <Link color={"#000000"} href="mailto:sk_smr24@mail.ru">
                                    sk_smr24@mail.ru
                                </Link>
                            </Typography>
                            <Typography align={"left"} fontFamily={'Montserrat'} fontSize={10}>
                                Адрес:
                                <Link color={"#000000"}
                                      href={'https://yandex.by/maps/213/moscow/house/bagrationovskiy_proyezd_7k2/Z04YcwdhS0IBQFtvfXt1cHtlbQ==/?from=tabbar&indoorLevel=1&ll=37.501277%2C55.741769&mode=search&sctx=ZAAAAAgAEAAaKAoSCfoOfuIAljtAERRdF35w%2BEpAEhIJcVga%2BFENtz8RTdcTXRd%2BsD8iBgABAgMEBSgKOABA9p4GSAFiM3JlYXJyPXNjaGVtZV9Mb2NhbC9HZW8vQ29sbGVjdGlvbnMvRW5hYmxlZE1peD1mYWxzZWoCdWGdAc3MTD2gAQCoAQC9ARxMI%2F7CAQEA6gEA8gEA%2BAEAggJJ0LHQsNCz0YDQsNGC0LjQvtC90L7QstGB0LrQuNC5INC%2F0YDQvtC10LfQtCA30LoyLCDQutC%2B0LwuIDE0INC40L3QtNC10LrRgYoCAJICAzIxM5oCDGRlc2t0b3AtbWFwcw%3D%3D&sll=37.501277%2C55.741769&source=serp_navig&sspn=0.012288%2C0.004626&text=багратионовский%20проезд%207к2%2C%20ком.%2014%20индекс&z=16.81'}>
                                    г.Москва, Багратионовский проезд д. 7к2, ком. 14
                                </Link>
                            </Typography>
                        </Box>
                    </Flex.Item>
                </Flex>
            </Box>
        </Flex>
    )
        ;
}

export default App;

